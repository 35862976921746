import styled from '@emotion/styled';
import { DateInput, DateTimeInput, SelectInput, Show, ShowProps, SimpleForm } from 'react-admin';

import {
  InferProps,
  ReactComponentLike,
  ReactElementLike,
  ReactNodeLike,
  Requireable,
  Validator,
} from 'prop-types';
import TextInput from '../../components/TextInput';

import { ListHeaderActionsView } from '../../components/ListHeaderActions';
import SectionTitle from '../../components/SectionTitle';
import Separator from '../../components/Separator';
import { StartGlobalProductStatus } from '../../config/constants';
const ActionStatusArray = StartGlobalProductStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const ActionItemView = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      'disableAuthentication'
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Show {...props} actions={<ListHeaderActionsView />}>
      <SimpleForm>
        <ConfigWrapper>
          <ConfigDetais>
            <SectionTitle label="Action Details" />
            <TextInput source="id" label="Ref." disabled fullWidth />
            <TextInput source="companyId" label="Company Ref." disabled />

            <TextInput source="actionType" label="Action" fullWidth disabled />
            <SelectInput source="status" choices={ActionStatusArray} disabled />

            <DateTimeInput source="scheduledAt" label="Scheduled At (UTC)" fullWidth disabled />
            <TextInput source="data" label="Data" disabled />

            <TextInput source="remarks" label="Remarks" fullWidth disabled />

            <DateInput
              source="createdDate"
              label="Created On"
              disabled
              style={{ marginRight: '1rem' }}
            />
            <DateInput source="modifiedDate" label="Last Modified On" disabled />
          </ConfigDetais>
        </ConfigWrapper>
        <Separator />
      </SimpleForm>
    </Show>
  );
};

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;

export default ActionItemView;

function data(data: any): void {
  throw new Error('Function not implemented.');
}
const ConfigWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
`;
const ConfigDetais = styled.div`
  width: 100%;
  & > * {
    width: 40%;
    margin: 0.5rem;
  }
`;
