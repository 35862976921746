import styled from '@emotion/styled';
import { Edit, SelectInput, ShowProps, SimpleForm } from 'react-admin';

import {
  InferProps,
  ReactComponentLike,
  ReactElementLike,
  ReactNodeLike,
  Requireable,
  Validator,
} from 'prop-types';
import TextInput from '../../components/TextInput';

import { ListHeaderActionsView } from '../../components/ListHeaderActions';
import SectionTitle from '../../components/SectionTitle';
import Separator from '../../components/Separator';
import { StartGlobalProductStatus } from '../../config/constants';
const ActionStatusArray = StartGlobalProductStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const tranformData = (data: any) => {
  return {
    ...data,
    scheduledAt:
      typeof data.scheduledAt === 'string'
        ? data.scheduledAt
        : data.scheduledAt?.toISOString().slice(0, 19),
  };
};
const dateString = (v: string | number | Date) => {
  /*
   * since v parameter coming is a string then you cannot use isNan
   * undefined is also coming as a parameter so !v is good equivalent
   */
  if (!v) return;

  let parsedDate = new Date(v);
  let adjustedDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000);

  /*
   * since parameter coming in is a string you need to pass string further, that's why I added toISOString() call
   */
  return adjustedDate.toISOString().slice(0, 19);
};

const ActionItemEdit = (
  props: JSX.IntrinsicAttributes &
    Pick<ShowProps<any>, keyof ShowProps<any>> &
    Pick<
      InferProps<{
        actions: Requireable<boolean | ReactElementLike>;
        children: Validator<ReactNodeLike>;
        className: Requireable<string>;
        disableAuthentication: Requireable<boolean>;
        emptyWhileLoading: Requireable<boolean>;
        component: Requireable<ReactComponentLike>;
        resource: Requireable<string>;
        title: Requireable<ReactNodeLike>;
        sx: Requireable<any>;
      }>,
      'disableAuthentication'
    > &
    Pick<ShowProps<any>, 'aside' | 'id' | 'queryOptions'>
) => {
  return (
    <Edit {...props} actions={<ListHeaderActionsView />} transform={tranformData}>
      <SimpleForm>
        <ConfigWrapper>
          <ConfigDetais>
            <SectionTitle label="Product Details" />
            <TextInput source="id" label="Ref." disabled fullWidth />
            <TextInput source="companyId" label="Company Ref." disabled />

            <SelectInput source="status" choices={ActionStatusArray} />

            <TextInput source="remarks" label="Remarks" fullWidth />
          </ConfigDetais>
        </ConfigWrapper>
        <Separator />
      </SimpleForm>
    </Edit>
  );
};

const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;

export default ActionItemEdit;

function data(data: any): void {
  throw new Error('Function not implemented.');
}
const ConfigWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2em;
`;
const ConfigDetais = styled.div`
  width: 100%;
  & > * {
    width: 40%;
    margin: 0.5rem;
  }
`;
