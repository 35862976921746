import EmployeeIcon from '@mui/icons-material/People';

import AddonProductEdit from './AddonProductEdit';
import AddonProductList from './AddonProductList';
import AddonProductShow from './AddonProductView';

const resource = {
  list: AddonProductList,
  edit: AddonProductEdit,
  show: AddonProductShow,
  icon: EmployeeIcon,
};

export default resource;
