import {
  Datagrid,
  ExportButton,
  FilterButton,
  ReferenceField,
  SelectField,
  SelectInput,
  TopToolbar,
} from 'react-admin';
import DateField from '../../components/DateField';
import List from '../../components/List';
import TextField from '../../components/TextField';
import TextInput from '../../components/TextInput';
import { StartGlobalProductStatus } from '../../config/constants';
const ActionStatusArray = StartGlobalProductStatus.map((f) => ({
  id: f.id,
  name: f.label,
}));

const Filters = [
  <TextInput source="companyId" label="Company Ref." alwaysOn />,
  <SelectInput source="status" choices={ActionStatusArray} alwaysOn />,
  // <ReferenceInput label="Company" source="companyId" reference="company-master-data" alwaysOn>
  //   <AutocompleteInput optionText="companyFullName" />
  // </ReferenceInput>,
];
const ListActions = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <ExportButton />
    </TopToolbar>
  );
};
const ActionItemList = () => {
  return (
    <List
      filters={Filters}
      // filterDefaultValues={{'isValid':'true'}}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<ListActions />}
    >
      <Datagrid
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        optimized
        rowClick={'show'}
        sx={{
          '& .column-groups': {
            md: { display: 'none' },
            lg: { display: 'table-cell' },
          },
        }}
        style={{ tableLayout: 'auto', whiteSpace: 'nowrap' }}
      >
        <TextField source="id" label="Ref." />
        <TextField source="companyId" label="Company Ref." />
        <ReferenceField
          source="companyId"
          label="Company Name"
          reference="company-master-data"
          emptyText="N/A"
          link="show"
        >
          <TextField source="companyFullName" />
        </ReferenceField>
        <TextField source="product" />
        <SelectField source="status" choices={ActionStatusArray} />
        <TextField source="remarks" />
        <TextField source="product" />

        <DateField
          source="startDate"
          label="Started On"
          showTime
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
        />
        {/* <DateField
          source="endDate"
          label="Ended On"
          showTime
          options={{ year: 'numeric', month: 'long', day: 'numeric' }}
        /> */}

        {/* <ShowButton label="Upload EIN"/> */}
      </Datagrid>
    </List>
  );
};

export default ActionItemList;
