import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { useCallback, useState } from 'react';
import { useNotify } from 'react-admin';
import { useDropzone } from 'react-dropzone';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import dataProvider from '../../config/dataProvider';
import CSVMappingModal from './CSVMappingModal';
import { Transaction } from './Transaction';
import processMercuryFile from './utils/mercuryUtil';
import processPayoneerFile from './utils/payoneerUtil';
import processpaypalFile from './utils/paypalUtils';
import processRelayfiFile from './utils/relayfiUtil';
import processWiseFile from './utils/wiseUtil';

export const BankStatements = () => {
  const [selectedBank, setSelectedBank] = useState('');
  const currentYear = new Date().getFullYear();
  const years = [currentYear, currentYear - 1, currentYear - 2];
  const [selectedYear, setSelectedYear] = useState(currentYear - 1);
  const [companyId, setCompanyId] = useState('');

  const notify = useNotify();

  const [openModal, setOpenModal] = useState(false);
  const [tableRows, setTableRows] = useState<Transaction[]>([]);
  const [showCSVModal, setShowCSVModal] = useState(false);
  const [csvData, setCsvData] = useState('');

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (selectedBank === 'CSV') {
        if (acceptedFiles.length > 0) {
          const file = acceptedFiles[0];
          const reader = new FileReader();
          reader.onload = () => {
            setCsvData(reader.result as string);
            setShowCSVModal(true);
          };
          reader.readAsText(file);
        }
        return;
      }
      if (!selectedBank) {
        notify('Please select a bank.', { type: 'error' });
        return;
      }
      let transactions: Transaction[] = [];
      if (selectedBank === 'MERCURY') {
        transactions = await processMercuryFile(acceptedFiles, selectedYear);
      } else if (selectedBank === 'RELAYFI') {
        transactions = await processRelayfiFile(acceptedFiles);
      } else if (selectedBank === 'WISE') {
        transactions = await processWiseFile(acceptedFiles);
      } else if (selectedBank === 'PAYONEER') {
        transactions = await processPayoneerFile(acceptedFiles);
      } else if (selectedBank === 'PAYPAL') {
        transactions = await processpaypalFile(acceptedFiles);
      }
      if (transactions.length === 0) {
        notify('Unable to read file.', { type: 'error' });
      } else {
        setTableRows(transactions);
        setOpenModal(true);
      }
      console.log({ transactions, year: selectedYear });
    },
    [selectedBank, selectedYear, notify]
  );

  const handleFieldChange = (index: number, field: keyof Transaction, value: string | number) => {
    const newRows = [...tableRows];
    newRows[index] = { ...newRows[index], [field]: value };
    setTableRows(newRows);
  };

  const handleSubmit = async () => {
    console.log('Submitting transactions:', tableRows);

    dataProvider
      .post(`company_bookkeeping_master/company/${companyId}/transactions`, { data: tableRows })
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Data updated successfully', { type: 'success' });
          return;
        }
        if (error) {
          throw new Error(error);
        }
        setOpenModal(false);
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, { type: 'error' });
      })
      .finally(() => {});
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: [
      'application/pdf',
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
    multiple: true,
  });

  const handleCSVMappingSubmit = (mappedTransactions: Transaction[]) => {
    setShowCSVModal(false);
    setTableRows(mappedTransactions);
    setOpenModal(true);
  };

  // Compute extra statistics for transactions
  const creditCount = tableRows.filter((row) => row.direction === 'CREDIT').length;
  const debitCount = tableRows.filter((row) => row.direction === 'DEBIT').length;
  const totalCreditValue = tableRows
    .filter((row) => row.direction === 'CREDIT')
    .reduce((sum, row) => sum + row.amount, 0);

  // const creditCount = 0;
  // const debitCount = 0;
  // const totalCreditValue = 0;

  // Row renderer for react-window
  const Row = ({ index, style }: ListChildComponentProps) => {
    const row = tableRows[index];
    return (
      <TableRow
        style={style}
        key={index}
        sx={{
          backgroundColor: row.direction === 'CREDIT' ? 'lightgreen' : 'lightcoral',
        }}
      >
        <TableCell sx={{ width: '20%' }}>
          <TextField
            variant="standard"
            size="small"
            value={row.date || ''}
            onChange={(e) => handleFieldChange(index, 'date', e.target.value)}
          />
        </TableCell>
        <TableCell sx={{ width: '40%' }}>
          <TextField
            variant="standard"
            size="medium"
            value={row.description || ''}
            onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
          />
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          <TextField
            variant="standard"
            size="small"
            value={row.amount || ''}
            onChange={(e) => handleFieldChange(index, 'amount', e.target.value)}
          />
        </TableCell>
        <TableCell sx={{ width: '20%' }}>
          <FormControl variant="standard" size="small" fullWidth>
            <Select
              value={row.direction || ''}
              onChange={(e) => handleFieldChange(index, 'direction', e.target.value)}
            >
              <MenuItem value="CREDIT">Credit</MenuItem>
              <MenuItem value="DEBIT">Debit</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box sx={{ p: 2 }}>
        <Card sx={{ maxWidth: 500, mx: 'auto' }}>
          <CardHeader title="Bank Statements" />
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <FormControl fullWidth style={{ maxWidth: 300 }}>
                  <InputLabel id="bank-select-label">Bank</InputLabel>
                  <Select
                    labelId="bank-select-label"
                    value={selectedBank}
                    label="Bank"
                    onChange={(e) => setSelectedBank(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Select Bank</em>
                    </MenuItem>
                    <MenuItem value="MERCURY">MERCURY</MenuItem>
                    <MenuItem value="RELAYFI">RELAYFI</MenuItem>
                    <MenuItem value="WISE">WISE</MenuItem>
                    <MenuItem value="PAYONEER">PAYONEER</MenuItem>
                    <MenuItem value="PAYPAL">PAYPAL</MenuItem>
                    <MenuItem value="CSV">CSV</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth style={{ maxWidth: 150 }}>
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    value={selectedYear}
                    label="Year"
                    onChange={(e) => setSelectedYear(Number(e.target.value))}
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  label="Company Ref"
                  value={companyId || ''}
                  onChange={(e) => setCompanyId(e.target.value)}
                  variant="standard"
                  sx={{ maxWidth: 200 }}
                />
              </Box>
              <Box
                {...getRootProps()}
                sx={{
                  border: '2px dashed #ccc',
                  p: 2,
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <input {...getInputProps()} />
                <p>Drag & drop your files here, or click to select files</p>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
      {openModal && (
        <Dialog open={openModal} onClose={() => setOpenModal(false)} maxWidth="lg" fullWidth>
          <DialogTitle>
            Transactions (Total: {tableRows.length}) - Credits: {creditCount} ($
            {totalCreditValue.toFixed(2)}), Debits: {debitCount}
          </DialogTitle>
          <DialogContent>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '20%' }}>Date</TableCell>
                  <TableCell sx={{ width: '40%' }}>Description</TableCell>
                  <TableCell sx={{ width: '20%' }}>Amount</TableCell>
                  <TableCell sx={{ width: '20%' }}>Credit/Debit</TableCell>
                </TableRow>
              </TableHead>
              <AutoSizer disableHeight>
                {({ width }: { width: number }) => (
                  <FixedSizeList
                    height={400} // adjust as needed
                    width={width}
                    itemSize={50} // adjust row height if needed
                    itemCount={tableRows.length}
                  >
                    {Row}
                  </FixedSizeList>
                )}
              </AutoSizer>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)}>Cancel</Button>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showCSVModal && (
        <CSVMappingModal
          csvData={csvData}
          onMappingSubmit={handleCSVMappingSubmit}
          onClose={() => setShowCSVModal(false)}
        />
      )}
    </>
  );
};
