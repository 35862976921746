import { QueryStats, RepeatOne } from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HighlightIcon from '@mui/icons-material/Highlight';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MoneyIcon from '@mui/icons-material/Money';
import PaidIcon from '@mui/icons-material/Paid';
import VisitorIcon from '@mui/icons-material/People';
import PhoneIcon from '@mui/icons-material/Phone';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import RowingIcon from '@mui/icons-material/Rowing';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import TableChartIcon from '@mui/icons-material/TableChart';
import WcIcon from '@mui/icons-material/Wc';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { MenuProps, usePermissions, useSidebarState, useTranslate } from 'react-admin';
import MenuItem from './MenuItem';

type MenuName =
  | 'dashboard'
  | 'admin'
  | 'manangement'
  | 'accounting'
  | 'reports'
  | 'pending'
  | 'company'
  | 'subscription'
  | 'compliance'
  | 'sales'
  | 'HR'
  | 'federalTax'
  | 'bookkeeping'
  | 'chats'
  | 'addons'
  | 'localBatch'
  | 'lenco'
  | 'ein8822b'
  | 'monitoring';

const Menu = ({ dense = false }: MenuProps) => {
  const { permissions } = usePermissions(); //["ROLE_USER","ROLE_ADMIN","ROLE_SUPER_ADMIN"]
  const INITIAL_STATE = {
    dashboard: false,
    admin: false,
    manangement: false,
    accounting: false,
    pending: false,
    reports: false,
    company: false,
    subscription: false,
    compliance: false,
    HR: false,
    sales: false,
    federalTax: false,
    bookkeeping: false,
    chats: false,
    addons: false,
    localBatch: false,
    lenco: false,
    ein8822b: false,
    monitoring: false,
  };

  const [state, setState] = useState({
    ...INITIAL_STATE,
    dashboard: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    //setState(state => ({ ...state, [menu]: !!!state[menu] }));
    setState({ ...INITIAL_STATE, [menu]: !!!state[menu] });
  };
  const hasPermission = (_permission: string) => {
    return permissions && Array.isArray(permissions) && permissions.indexOf(_permission) >= 0;
  };

  const KPIDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('dashboard')}
        isOpen={!!state.dashboard}
        name="Dashboards"
        icon={<DashboardIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          { to: '/kpi-service', label: 'KPI', icon: <AssessmentIcon className="sideMenuIcons" /> },
          {
            to: '/kpi-payments',
            label: 'KPI-Payments',
            icon: <AssessmentIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };
  const FederalTaxDashboard = (cpaId: number) => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('federalTax')}
        isOpen={true}
        name="Federal Tax"
        icon={<PriceCheckIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: `/federal-tax-summary/cpa${cpaId}`,
            label: 'Federal Tax',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },
          ...(cpaId === 3
            ? [
                {
                  to: '/federal-tax-summary/cpa2',
                  label: 'Zero Transactions',
                  icon: <PriceCheckIcon className="sideMenuIcons" />,
                },
                {
                  to: '/federal-tax-summary/penalties',
                  label: 'Penalty',
                  icon: <PriceCheckIcon className="sideMenuIcons" />,
                },
              ]
            : []),
          {
            to: '/federal-tax-summary/revisions',
            label: 'Revisions',
            icon: <RepeatOne className="sideMenuIcons" />,
          },
          // {
          //   to: '/be-12-surveys',
          //   label: 'BE12 Survey',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },
          // {
          //   to: '/federal-tax-chats',
          //   label: 'Chats',
          //   icon: <ChatIcon className="sideMenuIcons" />,
          // },
          {
            to: '/chats',
            label: 'Chat',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },
          ...(cpaId === 1 || cpaId === 2
            ? [
                {
                  to: '/company_dissolution_master',
                  label: 'Final Tax',
                  icon: <SettingsIcon className="sideMenuIcons" />,
                },
              ]
            : []),
        ]}
      />,
    ];
  };

  const SuperAdminFederalTaxDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('federalTax')}
        isOpen={!!state.federalTax}
        name="Federal Tax"
        icon={<PriceCheckIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/federal-tax-summary/all',
            label: 'Federal Tax (All)',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },
          {
            to: '/federal-tax-summary/revisions',
            label: 'Revisions',
            icon: <RepeatOne className="sideMenuIcons" />,
          },
          {
            to: '/federal-tax-summary/calculate-income',
            label: 'Calc. Income',
            icon: <QueryStats className="sideMenuIcons" />,
          },
          {
            to: `/federal-tax-summary/cpa1`,
            label: 'Federal Tax (Sam)',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },
          {
            to: `/federal-tax-summary/cpa2`,
            label: 'Zero Transactions',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },
          {
            to: `/federal-tax-summary/cpa3`,
            label: 'Strategcube',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },
          {
            to: `/federal-tax-summary/penalties`,
            label: 'Penalty',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },

          // {
          //   to: '/federal-tax-chats',
          //   label: 'Chats',
          //   icon: <ChatIcon className="sideMenuIcons" />,
          // },
          // {
          //   to: '/federal_tax_chat_new',
          //   label: 'New Chat',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },
          // {
          //   to: '/be-12-surveys',
          //   label: 'BE12 Survey',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },
          // {
          //   to: '/be-12-surveys/v2',
          //   label: 'BE12 Survey (2)',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },
          {
            to: '/chats',
            label: 'Chat',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },
          {
            to: '/bank-statements',
            label: 'Bank Statements',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const BookkeepingDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('bookkeeping')}
        isOpen={!!state.bookkeeping}
        name="Bookkeeping"
        icon={<MenuBookIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/bookkeeping_summary',
            label: 'Bookkeeping',
            icon: <MenuBookIcon className="sideMenuIcons" />,
          },
          // {
          //   to: '/bookkeeping-view-chats',
          //   label: 'Chats',
          //   icon: <ChatIcon className="sideMenuIcons" />,
          // },
          {
            to: '/chats',
            label: 'Chat',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const SuperAdminBookkeepingDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('bookkeeping')}
        isOpen={!!state.bookkeeping}
        name="Bookkeeping"
        icon={<MenuBookIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/bookkeeping_summary',
            label: 'Bookkeeper',
            icon: <MenuBookIcon className="sideMenuIcons" />,
          },
          // {
          //   to: '/bookkeeping-view-chats',
          //   label: 'Chats',
          //   icon: <ChatIcon className="sideMenuIcons" />,
          // },
          {
            to: '/company_bookkeeping_master',
            label: 'Books',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },
          {
            to: '/bookkeeping_pending_confirmation',
            label: 'Books (Not Confirmed)',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },
          {
            to: '/chats',
            label: 'Chat',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },
          {
            to: '/sg_view_federal_tax_bookkeeping_workflow',
            label: 'Tax Workflow',
            icon: <AccountTreeIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const AddonsDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('addons')}
        isOpen={!!state.addons}
        name="Addons"
        icon={<MenuBookIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/addons/addon_master',
            label: 'Addon Master',
            icon: <MenuBookIcon className="sideMenuIcons" />,
          },
          {
            to: '/addons/FEDERAL_TAX_EXTENSION/business',
            label: 'Business Extensions',
            icon: <MenuBookIcon className="sideMenuIcons" />,
          },
          {
            to: 'addons/FEDERAL_TAX_EXTENSION/individual',
            label: 'Individual Extensions',
            icon: <MenuBookIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const LencoDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('lenco')}
        isOpen={!!state.lenco}
        name="LencoAccount"
        icon={<MenuBookIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: 'lenco',
            label: 'Virtual Accounts',
            icon: <MenuBookIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const Ein8822bWorkflow = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('ein8822b')}
        isOpen={!!state.ein8822b}
        name="Ein8822b"
        icon={<MenuBookIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: 'ein_8822b_workflow',
            label: 'Ein8822b',
            icon: <MenuBookIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const CompanyDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('company')}
        isOpen={!!state.company}
        name="Company"
        icon={<BusinessIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/company-pending-orders',
            label: 'Pending Orders',
            icon: <TableChartIcon className="sideMenuIcons" />,
          },
          {
            to: '/company-archived-orders',
            label: 'Archived Orders',
            icon: <BrokenImageIcon className="sideMenuIcons" />,
          },
          {
            to: '/company-master-data',
            label: 'Companies',
            icon: <BusinessIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/passport/pending',
            label: 'Passport',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/ss4-service/pending-generation',
            label: 'SS4 (Not Generated)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/ss4/pending',
            label: 'SS4 (Not Signed)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/state-filing-service',
            label: 'State Filing',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/name-check-service/pending',
            label: 'Name Check Service',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/startglobal-state-filing-service',
            label: 'State Filings ( 48 states)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/startglobal-ein-service',
            label: 'EIN (SSN-Online) ',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/startglobal-fax-ein-service',
            label: 'EIN (No-SSN-Fax) ',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/startglobal-fax-delayed-ein-service',
            label: 'EIN (FAX Delayed)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/ein-service-error-workflow',
            label: 'EIN (Correction)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/ein-service-usacorps',
            label: 'EIN (USACorps)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },

          {
            to: '/mail-received-logs',
            label: 'Mails',
            icon: <AttachEmailIcon className="sideMenuIcons" />,
          },
          { to: '/fax-logs', label: 'Fax', icon: <AttachEmailIcon className="sideMenuIcons" /> },
          { to: '/action-items', label: 'Actions', icon: <RowingIcon className="sideMenuIcons" /> },
          {
            to: '/company_reinstatement_master',
            label: 'Reinstatements',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/company_dissolution_master',
            label: 'Dissolution',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/registered_agent_transfer_master',
            label: 'RA Transfer',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/mercury-bank-data/all',
            label: 'Bank Accounts',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/mercury-bank-data/approved',
            label: 'Bank Accounts (Approved)',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/company_compliance_master/all',
            label: 'Entity Management',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/company_compliance_master/upcoming-payments',
            label: 'Upcoming Payments',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/tax-estimator-addon',
            label: 'Tax Estimator Addon',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/plaid-accounts',
            label: 'Plaid Accounts',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/email-blacklist-master',
            label: 'BlackList Emails',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/operating-agreement-addon',
            label: 'Operating Agreement Addon',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/boi_master/all',
            label: 'Boi Filings',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/boi_master/onhold',
            label: 'Boi Filings (Onhold)',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/company_addon_product_master',
            label: 'Addon Products',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          // {
          //   to: '/plaid-companies',
          //   label: 'Plaid Companies',
          //   icon: <ManageAccountsIcon className="sideMenuIcons" />,
          // }, //performance issue
        ]}
      />,
    ];
  };
  const USACorpsDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('company')}
        isOpen={!!state.company}
        name="Company"
        icon={<BusinessIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: 'workflows/startglobal-fax-delayed-ein-service',
            label: 'EIN (FAX Delayed)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/ein-service-error-workflow',
            label: 'EIN (Correction)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: 'workflows/ein-service-usacorps',
            label: 'EIN (USACorps)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const AdminDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('admin')}
        isOpen={!!state.admin}
        name="Admin"
        icon={<ManageAccountsIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/registered-agent-payments',
            label: 'Reg Agent Payments',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/franchise-tax',
            label: 'State Tax',
            icon: <PaidIcon className="sideMenuIcons" />,
          },

          {
            to: '/company-archived-master',
            label: 'Company Archives',
            icon: <PaidIcon className="sideMenuIcons" />,
          },
          {
            to: '/franchise-master',
            label: 'Franchises',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/phone-number-registration-details',
            label: 'Phone Number',
            icon: <PhoneIcon className="sideMenuIcons" />,
          },
          {
            to: '/email-registration-details',
            label: 'Email',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };
  const MonitoringDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('monitoring')}
        isOpen={!!state.monitoring}
        name="Monitoring"
        icon={<ManageAccountsIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/approvals/franchise-tax',
            label: 'State Tax Approval',
            icon: <PaidIcon className="sideMenuIcons" />,
          },
          {
            to: '/approvals/existing-company-onboarding',
            label: 'Existing Company Onboarding',
            icon: <PaidIcon className="sideMenuIcons" />,
          },
          {
            to: '/chats',
            label: 'Chat',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };
  const ComplianceDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('compliance')}
        isOpen={!!state.compliance}
        name="Compliance"
        icon={<PublishedWithChangesIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/registered-agent-payments',
            label: 'Reg Agent Payments',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/franchise-tax',
            label: 'State Tax',
            icon: <PaidIcon className="sideMenuIcons" />,
          },

          {
            to: '/franchise-master',
            label: 'Franchises',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/phone-number-registration-details',
            label: 'Phone Number',
            icon: <PhoneIcon className="sideMenuIcons" />,
          },
          {
            to: '/email-registration-details',
            label: 'Email',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const SalesDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('sales')}
        isOpen={!!state.sales}
        name="Sales"
        icon={<ManageAccountsIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/company-leads',
            label: 'Leads',
            icon: <LeaderboardIcon className="sideMenuIcons" />,
          },
          {
            to: '/phone-number-registration-details',
            label: 'Phone Number',
            icon: <PhoneIcon className="sideMenuIcons" />,
          },
          {
            to: '/email-registration-details',
            label: 'Email',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const SDRDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('sales')}
        isOpen={!!state.sales}
        name="Sales"
        icon={<ManageAccountsIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/company_bookkeeping_master',
            label: 'Books',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },

          {
            to: '/bookkeeping_pending_confirmation',
            label: 'Books (Not Confirmed)',
            icon: <LibraryBooksIcon className="sideMenuIcons" />,
          },
          {
            to: '/bookkeeping_summary',
            label: 'Bookkeeping',
            icon: <MenuBookIcon className="sideMenuIcons" />,
          },
          {
            to: '/federal-tax-summary/all',
            label: 'Federal Tax (All)',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },
          {
            to: '/federal-tax-summary/revisions',
            label: 'Revisions',
            icon: <RepeatOne className="sideMenuIcons" />,
          },
          {
            to: 'workflows/startglobal-fax-delayed-ein-service',
            label: 'EIN (FAX Delayed)',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: '/company-master-data',
            label: 'Companies',
            icon: <BusinessIcon className="sideMenuIcons" />,
          },
          {
            to: '/company_compliance_master/all',
            label: 'Entity Management',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/company_compliance_master/upcoming-payments',
            label: 'Upcoming Payments',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/email-blacklist-master',
            label: 'BlackList Emails',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          // {
          //   to: '/company-pending-payment-master',
          //   label: 'Pending Companies',
          //   icon: <ManageAccountsIcon className="sideMenuIcons" />,
          // },
        ]}
      />,
      <MenuItem
        handleToggle={() => handleToggle('federalTax')}
        isOpen={!!state.federalTax}
        name="Federal Tax"
        icon={<PriceCheckIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/federal-tax-summary/all',
            label: 'Federal Tax (All)',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },

          {
            to: `/federal-tax-summary/cpa1`,
            label: 'Federal Tax (Sam)',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },
          {
            to: `/federal-tax-summary/cpa2`,
            label: 'Zero Transactions',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },
          {
            to: `/federal-tax-summary/cpa3`,
            label: 'Strategcube',
            icon: <PriceCheckIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const BatchDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('localBatch')}
        isOpen={!!state.localBatch}
        name="Batches"
        icon={<ManageAccountsIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/local-batches',
            label: 'Local',
            icon: <LeaderboardIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const HRDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('HR')}
        isOpen={!!state.HR}
        name="HR"
        icon={<WcIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/sg-employee-master',
            label: 'Employees',
            icon: <VisitorIcon className="sideMenuIcons" />,
          },
          {
            to: '/sg-employee-compensation-master',
            label: 'Payroll',
            icon: <MoneyIcon className="sideMenuIcons" />,
          },
          { to: '/sg-ads-master', label: 'Ads', icon: <HighlightIcon className="sideMenuIcons" /> },
          {
            to: '/sg-expense-master',
            label: 'Expense',
            icon: <PaidIcon className="sideMenuIcons" />,
          },
          {
            to: '/kpi-configuration-parameters',
            label: 'Cost Parameters',
            icon: <SettingsIcon className="sideMenuIcons" />,
          },
          {
            to: '/kpi-instance-parameters',
            label: 'Monthwise Cost',
            icon: <SettingsSuggestIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };
  const SubscriptionDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('subscription')}
        isOpen={!!state.subscription}
        name="Subscription"
        icon={<AutorenewIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/sg_view_subscription_master_data',
            label: 'Subscriptions',
            icon: <SubscriptionsIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const GrowthHackerDashboard = () => {
    return [
      ...CompanyDashboard(),
      ...SuperAdminFederalTaxDashboard(),
      ...SuperAdminBookkeepingDashboard(),
      ...AddonsDashboard(),
      ...KPIDashboard(),
      ...ReportsDashboard(),

      <MenuItem
        handleToggle={() => handleToggle('subscription')}
        isOpen={!!state.subscription}
        name="Subscription"
        icon={<AutorenewIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/sg_view_subscription_master_data',
            label: 'Subscriptions',
            icon: <SubscriptionsIcon className="sideMenuIcons" />,
          },
        ]}
      />,
    ];
  };

  const ReportsDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('reports')}
        isOpen={!!state.reports}
        name="Reports Mangement"
        icon={<AssessmentIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          { to: '/reports', label: 'Reports', icon: <VisitorIcon className="sideMenuIcons" /> },
        ]}
      />,
    ];
  };
  const StateFilerDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('chats')}
        isOpen={!!state.chats}
        name="Chats"
        icon={<AssessmentIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/company-master-data',
            label: 'Companies',
            icon: <BusinessIcon className="sideMenuIcons" />,
          },
          {
            to: '//all',
            label: 'Boi Filings',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          {
            to: '/company_addon_product_master',
            label: 'Addon Products',
            icon: <ManageAccountsIcon className="sideMenuIcons" />,
          },
          // {
          //   to: '/chats',
          //   label: 'Bookkeeping Chat',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },

          // {
          //   to: '/federal_tax_chat_new',
          //   label: 'Federal Tax Chat',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },
          // {
          //   to: '/be-12-surveys/v2',
          //   label: 'BE12 Survey',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },
        ]}
      />,
    ];
  };
  const HatchlyAdminDashboard = () => {
    return [
      <MenuItem
        handleToggle={() => handleToggle('company')}
        isOpen={!!state.company}
        name="Company"
        icon={<BusinessIcon className="dashboardIcons" />}
        dense={dense}
        subitems={[
          {
            to: '/hatchly-master-data',
            label: 'Companies',
            icon: <BusinessIcon className="sideMenuIcons" />,
          },

          // {
          //   to: '/chats',
          //   label: 'Bookkeeping Chat',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },

          // {
          //   to: '/federal_tax_chat_new',
          //   label: 'Federal Tax Chat',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },
          // {
          //   to: '/be-12-surveys/v2',
          //   label: 'BE12 Survey',
          //   icon: <LibraryBooksIcon className="sideMenuIcons" />,
          // },
        ]}
      />,
    ];
  };

  if (!hasPermission('ROLE_ADMIN')) {
    return null;
  }

  if (hasPermission('ROLE_EIN_SERVICE_STARTGLOBAL')) {
    return (
      <Box
        sx={{
          width: open ? 250 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {[
          ...CompanyDashboard(),
          ...MonitoringDashboard(),
          ...SuperAdminFederalTaxDashboard(),
          ...SuperAdminBookkeepingDashboard(),
        ]}
      </Box>
    );
  }
  if (hasPermission('ROLE_SEO_MANAGER')) {
  }
  if (hasPermission('ROLE_STATE_FILER')) {
    return (
      <Box
        sx={{
          width: open ? 250 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {[...StateFilerDashboard()]}
      </Box>
    );
  }
  // if (hasPermission('ROLE_CPA_0988')) {
  //   return (
  //     <Box
  //       sx={{
  //         width: open ? 250 : 50,
  //         marginTop: 1,
  //         marginBottom: 1,
  //         transition: (theme) =>
  //           theme.transitions.create('width', {
  //             easing: theme.transitions.easing.sharp,
  //             duration: theme.transitions.duration.leavingScreen,
  //           }),
  //       }}
  //     >
  //       {[...FederalTaxDashboard()]}
  //     </Box>
  //   );
  // }
  if (hasPermission('ROLE_CPA_0128') || hasPermission('ROLE_CPA_1')) {
    return (
      <Box
        sx={{
          width: open ? 250 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {[...FederalTaxDashboard(1)]}
      </Box>
    );
  }
  if (hasPermission('ROLE_CPA_3')) {
    return (
      <Box
        sx={{
          width: open ? 250 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {[...FederalTaxDashboard(3)]}
      </Box>
    );
  }

  if (hasPermission('ROLE_BOOK_KEEPER')) {
    return (
      <Box
        sx={{
          width: open ? 250 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {[...BookkeepingDashboard()]}
      </Box>
    );
  }
  if (hasPermission('ROLE_EIN_SERVICE_USA_CORPS')) {
    return (
      <Box
        sx={{
          width: open ? 250 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {[...USACorpsDashboard()]}
      </Box>
    );
  }
  if (hasPermission('ROLE_GROWTH_HACKER')) {
    return (
      <Box
        sx={{
          width: open ? 250 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {[...GrowthHackerDashboard()]}
      </Box>
    );
  }
  if (hasPermission('ROLE_SDR')) {
    return (
      <Box
        sx={{
          width: open ? 250 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {[...SDRDashboard()]}
      </Box>
    );
  }
  if (hasPermission('ROLE_HATCHLY_ADMIN')) {
    return (
      <Box
        sx={{
          width: open ? 250 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        {[...HatchlyAdminDashboard()]}
      </Box>
    );
  }

  //else super admin
  return (
    <Box
      sx={{
        width: open ? 250 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      {[
        ...KPIDashboard(),
        ...CompanyDashboard(),
        ...MonitoringDashboard(),
        ...AdminDashboard(),
        ...ComplianceDashboard(),
        ...SalesDashboard(),
        ...HRDashboard(),
        ...SubscriptionDashboard(),
        ...ReportsDashboard(),
        ...SuperAdminFederalTaxDashboard(),
        ...SuperAdminBookkeepingDashboard(),
        ...AddonsDashboard(),
        ...BatchDashboard(),
        // ...LencoDashboard(),
        // ...Ein8822bWorkflow(),
      ]}
    </Box>
  );
};

export default Menu;
