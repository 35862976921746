import styled from '@emotion/styled';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import last from 'lodash.last';
import * as React from 'react';
import { useMemo } from 'react';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { useForm } from 'react-hook-form';

import BookkeepingStatusConfirmationForm from './bookkeeping-forms/BookkeepingStatusConfirmationForm';
import BookkeepingStatusForm from './bookkeeping-forms/BookkeepingStatusForm';
import PaymentStatusForm from './bookkeeping-forms/PaymentStatusForm';
import CompanyStartupPayments from './CompanyStartupPayments';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddCardIcon from '@mui/icons-material/AddCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CachedIcon from '@mui/icons-material/Cached';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Select from 'react-select';
import * as XLSX from 'xlsx/xlsx.mjs';
import DocumentListItem from '../../../components/DocumentListItem';
import { CompanyDocumentPurposeForBookkeeping } from '../../../config/constants';
import { hasPermission } from '../../../lib/SGHelper';
import BookkeepingAmountForm from './bookkeeping-forms/BookkeepingAmountForm';
import TransactionCountForm from './bookkeeping-forms/TransactionCountForm';

import { CustomTextField } from '../../../components/CustomTextField';
import SubmitButton from './../forms/SubmitButton';
import CategorizationCompletedForm from './bookkeeping-forms/CategorizationCompletedForm';
import SubmittedForBookkeeperForm from './bookkeeping-forms/SubmittedForBookkeeperForm';

const Spacer = () => <Box m={1}>&nbsp;</Box>;
const Label = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75em;
  margin-bottom: 0.2em;
`;

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const BookkeepingMaster = (props) => {
  const [value, setValue] = React.useState(0);
  const [loading, setIsloading] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const { permissions } = usePermissions();
  const isBookkeeper = hasPermission('ROLE_BOOK_KEEPER', permissions || props.permissions);

  //const isBookkeeper = ;

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setIsloading(true);
    const url = `company_bookkeeping_master/company/${props.data.id}`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        setRows(() => data);
        if (error) {
          throw new Error(error);
        }
        const _rows = data?.filter((d) =>
          isBookkeeper ? ['ACTIVE', 'CLOSED'].indexOf(d.status) >= 0 : true
        );

        if (_rows.length > 0) {
          setValue(_rows.length - 1);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  if (loading) {
    return null;
  }
  if (rows.length <= 0) {
    return <IntiateBookkeepingEntry companyId={props.data?.id} />;
  }
  /**
   * Show only Active/Closed rows for bookkeeper.
   */
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={2} sm={2} md={2} style={{ width: '100%' }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
            style={{ width: '100%' }}
          >
            {rows
              ?.filter((d) => (isBookkeeper ? ['ACTIVE', 'CLOSED'].indexOf(d.status) >= 0 : true))
              .map((d, index) => (
                <StyledTab
                  label={d.year}
                  {...a11yProps(index)}
                  style={{ alignItems: 'flex-start', whiteSpace: 'nowrap' }}
                />
              ))}
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={10} md={10}>
          {rows
            ?.filter((d) => (isBookkeeper ? ['ACTIVE', 'CLOSED'].indexOf(d.status) >= 0 : true))
            .map((d, index) => (
              <TabPanel value={value} index={index}>
                <BookkeepingDetail data={d} subTab={props.subTab} company={props.data.company} />
              </TabPanel>
            ))}
        </Grid>
      </Grid>
    </Box>
  );
};

/**
 * Yearwise detail
 */
const BookkeepingDetail = (props) => {
  const getTabValue = (tab) => {
    switch (tab) {
      case 'chats':
        return 2;
      default:
        return 0;
    }
  };
  const [value, setValue] = React.useState(props?.subTab ? getTabValue(props?.subTab) : 0);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Details" {...a11yProps(0)} />
              <Tab label="Documents" {...a11yProps(1)} />
              <Tab label="Chats" {...a11yProps(2)} />
              <Tab label="Transactions" {...a11yProps(3)} />
              <Tab label="Payments" {...a11yProps(3)} />
            </Tabs>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <TabPanel value={value} index={0}>
              {' '}
              <BookkeepingDetailDetail
                data={props.data}
                company={props.company}
                year={props.data.year}
              />{' '}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {' '}
              <BookkeepingDocuments data={props.data} />{' '}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {' '}
              <BookkeepingChats data={props.data} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              {' '}
              <Transactions data={props.data} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <CompanyStartupPayments companyId={props.company?.id} />
            </TabPanel>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const Transactions = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [data, setData] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [loading, setIsloading] = React.useState(false);
  React.useEffect(() => {
    setIsloading(true);
    const url = `company_bookkeeping_master/company/${props.data.companyId}/books/${props.data.year}/transactions`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('transactions', data);
        setData(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  if (loading) {
    return (
      <>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </>
    );
  }
  return (
    <>
      {data && data.length > 0 ? (
        <>
          <div style={{ marginBottom: '24px', display: 'flex', gap: '2em' }}>
            <Button
              variant="contained"
              endIcon={<RefreshIcon />}
              onClick={() => {
                setReload(!reload);
              }}
            >
              REFRESH
            </Button>
            {/* <Button variant="contained" endIcon={<FilterAltIcon />} onClick={() => {
                        setData(data.filter(item => item.queries.length > 0))
                    }}>
                        SHOW TRANSACTIONS WITH QUERIES
                    </Button> */}
          </div>
          <TransactionTable data={data} />
        </>
      ) : (
        <div>NO records found</div>
      )}
    </>
  );
};

const TransactionTable = (props) => {
  return (
    <>
      <RowHeader style={{ background: '#1a1a1a', color: '#FFFFFF' }}>
        <div>DATE</div>
        <div>DESCRIPTION</div>
        <div>AMOUNT</div>
        <div>CATEGORY</div>
        <div>DESCRIPTION</div>

        <div>{`  `}</div>
      </RowHeader>
      <div>
        {' '}
        {props.data.map((item) => (
          <TableRow data={item} />
        ))}
      </div>
    </>
  );
};

const Row = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 2fr 0.5fr 1fr 1fr 1fr;
  // border: 1px solid #EEEEEE;
  // background: #ffffff;
  // padding: 10px 16px;
  // border-radius: 4px;
  align-items: center;
  grid-column-gap: 1.5em;
`;

const RowWrapper = styled.div`
  border: 1px solid #eeeeee;
  background: #ffffff;
  padding: 10px 16px;
  border-radius: 4px;
`;

const RowHeader = styled.div`
  display: grid;
  grid-template-columns: 0.6fr 2fr 0.5fr 1fr 1fr 1fr;
  border: 1px solid #eeeeee;
  background: #ffffff;
  padding: 10px 16px;
  border-radius: 4px;
  align-items: center;
  grid-column-gap: 1.5em;
`;

const TableRow = (props) => {
  const [queryOpen, setIsQueryOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState({});
  const [query, setQuery] = React.useState();
  const [processing, setProcessing] = React.useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const submitQuery = () => {
    if (!query) {
      return;
    }
    const data = {
      companyId: props.data.companyId,
      queryType: 'PLAID_TRANSACTION_ADDITIONAL_DETAIL',
      answerType: 'CHAR_1024',
      module: 'BANK_ACCOUNT_TRANSACTION',
      query: query,
      dataId: props.data.id,
    };
    setProcessing(true);
    dataProvider
      .post(`company_query_master/`, { data })
      .then(({ data, error }) => {
        if (data?.id) {
          notify('Query Saved', 'success');
          setIsQueryOpen(false);
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <>
      <RowWrapper>
        <Row key={props.data.id}>
          <div>{props.data.date}</div>
          <div>{props.data.name}</div>
          <div style={{ color: props.data.amount < 0 ? '#207A39' : '#E74663', textAlign: 'start' }}>
            $ {props.data.amount < 0 ? `${Math.abs(props.data.amount)}` : `${props.data.amount}`}
          </div>
          <div>{props.data?.userEnteredCategory || props.data?.detailedPersonalCategory}</div>

          <div>
            {props.data?.userEnteredCategoryLabel || props.data?.detailedPersonalCategoryLabel}
          </div>
          <div>
            <Button
              variant="contained"
              endIcon={<AddCircleIcon />}
              onClick={() => {
                setIsQueryOpen(true);
                setSelectedRow(props.data);
              }}
            >
              ADD QUERY
            </Button>
          </div>
        </Row>
        {props.data?.queries?.length > 0 && (
          <QueryWrap
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '12px',
              borderRadius: '4px',
            }}
          >
            {props.data?.queries.map((item, i) => (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '4fr 4fr 0.5fr',
                  margin: '0px 16px',
                  gap: '1em',
                  borderBottom:
                    i === props.data?.queries?.length - 1 ? 'none' : '1px solid #D9C28A',
                  padding: '10px',
                  alignItems: 'center',
                }}
              >
                <div>{item.query}</div>
                <div
                  style={{
                    background: item?.answer ? '#0bf286bf' : '#f20b2500',
                    padding: '10px 16px',
                    borderRadius: '4px',
                  }}
                >
                  {item?.answer ? item?.answer : '  '}
                </div>

                <div>
                  {item?.answer ? (
                    <CheckCircleOutlineIcon style={{ color: '#207A39' }} />
                  ) : (
                    <PendingIcon style={{ color: '#E74663' }} />
                  )}
                </div>
              </div>
            ))}
          </QueryWrap>
        )}
      </RowWrapper>
      <Dialog fullWidth open={queryOpen} onClose={() => setIsQueryOpen(false)}>
        <DialogTitle>Add Questions</DialogTitle>
        <DialogContent style={{ minHeight: 400, marginTop: '8px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1em',
              marginBottom: '24px',
              background: 'aliceblue',
              padding: '16px',
              borderRadius: '6px',
              border: '1px solid #cfe9ff',
            }}
          >
            <div style={{ display: 'flex', fontSize: '1.2em', color: '#444c66', fontWeight: 600 }}>
              Transaction Details
            </div>
            <div style={{ display: 'flex', gap: '1em' }}>
              <div>Date</div>: {selectedRow?.date}
            </div>
            <div style={{ display: 'flex', gap: '1em' }}>
              <div>Description</div>: {selectedRow?.name}
            </div>
            <div
              style={{
                color: selectedRow?.amount < 0 ? '#207A39' : '#E74663',
                textAlign: 'start',
                display: 'flex',
                gap: '1em',
              }}
            >
              <div>Amount</div>: ${' '}
              {selectedRow?.amount < 0
                ? `${Math.abs(selectedRow?.amount)}`
                : `${selectedRow?.amount}`}
            </div>
            <div style={{ display: 'flex', gap: '1em' }}>
              <div>Category</div>: {selectedRow?.primaryPersonalCategory}
            </div>
          </div>
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Type your question!"
            style={{ width: '100%', height: '10vh', marginBottom: '12px' }}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button
            loading={processing}
            variant="contained"
            endIcon={<SendIcon />}
            onClick={() => {
              submitQuery();
            }}
          >
            SAVE
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsQueryOpen(false)}>CLOSE</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const QueryWrap = styled.div`
  background: #fff3d6;
  width: 100%;
`;

const BookkeepingDetailDetail = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [downloading, setDownloading] = React.useState(false);
  const { permissions } = usePermissions();
  const showPaymentAmounts = hasPermission(
    ['ROLE_SALES', 'ROLE_SUPER_ADMIN', 'ROLE_EIN_SERVICE_STARTGLOBAL'],
    permissions
  );
  const isBookkeeper = hasPermission('ROLE_BOOK_KEEPER', permissions);
  const [documents, setDocuments] = React.useState([]);
  const [isLoading, setIsloading] = React.useState(false);

  console.log('props', props);

  React.useEffect(() => {
    setIsloading(true);
    const url = `company_bookkeeping_master/company/${props.data.companyId}/user-documents/${props.data.year}/documents`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        setDocuments(() => data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, []);

  const downloadTransactions = () => {
    const url = `company_bookkeeping_master/company/${props.data.companyId}/books/${props.data.year}/transactions`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('federal-tax-documents', data);
        if (error) {
          throw new Error(error);
        }
        const queries = '';
        let filteredData = data.map((d) => {
          return {
            id: d.id,
            currency: d.currency,
            amount: d.amount,
            authorisedDate: d.authorisedDate,
            date: d.date,
            name: d.name,
            merchantName: d.merchantName,
            institutionName: d.institutionName,
            year: d.year,
            primaryCategory: d.primaryPersonalCategory,
            detailedPersonalCategory: d.detailedPersonalCategory,
            category: d.category,
            notes: d.notes,
            queries,
          };
        });
        const companyName =
          props.company.companyName.replace(/\s+/g, '_') +
          '_' +
          props.company.companyNameType +
          '_' +
          props.data.year +
          '.xlsb';
        var workbook = XLSX.utils.book_new();

        var ws = XLSX.utils.json_to_sheet(filteredData, {
          header: [
            'id',
            'currency',
            'amount',
            'authorisedDate',
            'date',
            'name',
            'merchantName',
            'institutionName',
            'year',
            'primaryCategory',
            'detailedPersonalCategory',
            'category',
            'notes',
            'queries',
          ],
        });
        // {header: ["A", "B", "C", "D", "E", "F", "G"], skipHeader: true});
        XLSX.utils.book_append_sheet(workbook, ws, props.data.year + '');

        XLSX.writeFile(workbook, companyName);
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setDownloading(false));
  };
  if (isLoading) {
    return;
  }
  return (
    <>
      <PaymentStatusForm data={props.data} disabled={isBookkeeper} />
      <BookkeepingStatusForm data={props.data} isBookkeeper={isBookkeeper} />
      {!isBookkeeper && (
        <BookkeepingStatusConfirmationForm data={props.data} disabled={isBookkeeper} />
      )}
      {!isBookkeeper && <CategorizationCompletedForm data={props.data} disabled={isBookkeeper} />}
      {!isBookkeeper && <SubmittedForBookkeeperForm data={props.data} disabled={isBookkeeper} />}
      {showPaymentAmounts && <BookkeepingAmountForm data={props.data} />}
      <TransactionCountForm data={props.data} disabled={isBookkeeper} />
      <Button
        variant="contained"
        startIcon={<DownloadIcon />}
        onClick={downloadTransactions}
        disabled={downloading}
      >
        Download Transactions
      </Button>

      <Spacer />
      <Spacer />
      <Spacer />
      {documents.length > 0 && (
        <Grid item xs={10} sm={10} md={10}>
          <Typography>Supported Documents Uploaded By User</Typography>
          <List>
            {documents.map((d) => (
              <DocumentListItem
                id={d.id}
                companyId={d.companyId}
                purpose={d.purpose}
                text={d.originalFile}
                uuid={d.uuid}
                exists
                readOnly
              />
            ))}
          </List>
        </Grid>
      )}
    </>
  );
};
const BookkeepingDocuments = (props) => {
  console.log('federal tax', props.data);
  const [value, setValue] = React.useState(0);
  const [loading, setIsloading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [showAddDocument, setShowAddDocument] = React.useState(false);
  const [selectedPurpose, setSelectedPurpose] = React.useState(null);

  const notify = useNotify();
  const dataProvider = useDataProvider();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleUpload = ({ target }) => {
    const purpose = selectedPurpose;
    const acceptedFiles = target.files;
    if (!purpose) {
      notify('Unable to upload file', 'error');
    }
    if (!acceptedFiles) {
      return;
    }
    dataProvider
      .upload(`company-documents/upload/${props.data.year}`, {
        data: {
          files: acceptedFiles[0],
          companyId: props.data.companyId,
          purpose: purpose,
          keyId: props.data.id,
        },
      })
      .then((data) => {
        notify('File uploaded successfully', 'success');
        setReload((reload) => !reload);
        setShowAddDocument(false);
      })
      .catch((error) => {
        notify('Oh no.. Error occured', 'warning');
      });
  };
  React.useEffect(() => {
    setIsloading(true);
    const url = `company_bookkeeping_master/company/${props.data.companyId}/books/${props.data.year}/documents`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        console.log('federal-tax-documents', data);
        //data comes in the ascending order.
        //But we like to keep the most recent order at the top
        setData(data);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  if (loading) {
    return null;
  }

  return (
    <>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={7} />
          <Grid item xs={3} sm={3} md={3}>
            <IconButton size="large" color="primary" onClick={() => setShowAddDocument(true)}>
              <AddCardIcon fontSize="large" />
            </IconButton>
            <IconButton size="large" color="primary" onClick={() => setReload((reload) => !reload)}>
              <CachedIcon fontSize="large" />
            </IconButton>
          </Grid>

          <Grid item xs={10} sm={10} md={10}>
            <Dialog fullWidth open={showAddDocument} onClose={() => setShowAddDocument(false)}>
              <DialogTitle>Add New Document</DialogTitle>
              <DialogContent style={{ minHeight: 400 }}>
                <Select
                  name="purpose"
                  options={CompanyDocumentPurposeForBookkeeping.map((c) => {
                    return { value: c.id, label: c.label };
                  })}
                  onChange={(selectedOption) => setSelectedPurpose(selectedOption?.value)}
                />

                <input
                  accept="application/zip, application/pdf, image/*, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  id={'s'}
                  style={{ display: 'none' }}
                  onChange={handleUpload}
                  type="file"
                  disabled={!!!selectedPurpose}
                />
                <label htmlFor={'s'}>
                  <Tooltip title="Upload">
                    <IconButton
                      edge="end"
                      aria-label="upload"
                      component="span"
                      disabled={!!!selectedPurpose}
                    >
                      <UploadIcon className="documentIcons" />
                    </IconButton>
                  </Tooltip>
                </label>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setShowAddDocument(false)}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </Grid>
          <Grid item xs={10} sm={10} md={10}>
            <List>
              {data.length ? (
                data.map((d) => (
                  <DocumentListItem
                    id={d.id}
                    companyId={d.companyId}
                    purpose={d.purpose}
                    uuid={d.uuid}
                    text={d.purpose}
                    exists
                  />
                ))
              ) : (
                <div>No Data found</div>
              )}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
const BookkeepingChats = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setIsloading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [reload, setReload] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [messageId, setMessageId] = React.useState(0);
  const [currentMessage, setCurrentMessage] = React.useState('');
  React.useEffect(() => {
    setIsloading(true);
    const url = `chat_master/${props.data.companyId}/owner/BOOKKEEPER`;
    dataProvider
      .get(url, {})
      .then(({ data, error }) => {
        const _ms = data.map((m) => {
          const myMessage = m.sendFrom === 'CLIENT';
          const chatId = m.id;
          return {
            id: chatId,
            content: {
              type: 'text',
              body: m.message,
            },
            date: m.createdDate,
            sender: myMessage,
          };
        });
        setData(_ms);
        if (_ms.length > 0) {
          setMessageId(_ms[0].id);
        }
        setMessages(_ms);
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setIsloading(false));
  }, [reload]);

  const addChat = (msg) => {
    let messages_ = messages;

    setMessages([
      ...messages,
      {
        content: {
          type: 'text',
          body: msg,
        },
        sender: false,
      },
    ]);
  };
  const onChangeOfInputMessage = (e) => {
    setCurrentMessage(e.target.value);
  };

  const onKeyPressOfInputMessage = (e) => {
    if (e.key == 'Enter') {
      if (!currentMessage) {
        return;
      }
      saveChatMessage(currentMessage);
      addChat(currentMessage);
      setCurrentMessage('');
    }
  };

  const saveChatMessage = (msg) => {
    if (!msg) {
      return;
    }
    const data = {
      message: msg,
      companyId: Number(props.data.companyId),
      sendFrom: 'BOOKKEEPER',
    };

    dataProvider
      .post(`chat_master/reply/${messageId}`, { data })
      .then(({ data, error }) => {
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => {});
  };

  return (
    <ChatWrapper>
      <div
        style={{
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: '50vh',
        }}
      >
        <MessageList messages={messages} />
      </div>
      <div
        style={{
          padding: '16px 16px',
          display: 'flex',
          border: '1px solid #EEEEEE',
          borderRadius: '4px',
          width: '-webkit-fill-available',
          display: 'flex',
          gap: '1em',
        }}
      >
        <TextField
          id="filled-basic"
          label=""
          variant="filled"
          placeholder="Type here!"
          fullWidth
          value={currentMessage}
          onChange={onChangeOfInputMessage}
          onKeyPress={onKeyPressOfInputMessage}
        />
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          onClick={() => {
            saveChatMessage(currentMessage);
            if (currentMessage) {
              addChat(currentMessage);
              setCurrentMessage('');
            }
          }}
        >
          Send
        </Button>
      </div>
    </ChatWrapper>
  );
};

const ChatWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content:space-between'
background: white;
border: 1px solid #EEEEEE;
border-radius: 4px;
over-flow:auto;
width:70%;
`;

const MessageList = (props) => {
  const senderGroupedMessages = useMemo(
    () =>
      props.messages.reduce((arr, m) => {
        const sender = m.sender;
        if (!last(arr)) {
          arr.push({
            messages: [m],
            sender,
          });
        } else if (last(arr)?.sender === sender) {
          last(arr)?.messages.push(m);
        } else {
          arr.push({
            messages: [m],
            sender,
          });
        }
        return arr;
      }, []),
    [props.messages]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
      {senderGroupedMessages.map((mg) => (
        <MessageGroup key={mg.messages[0].id} {...mg} />
      ))}
    </div>
  );
};

const MessageGroup = (props) => {
  return (
    <MessageGroupWrapper>
      {props.sender ? (
        <div className="pt-2 pr-2">
          <AccountCircleIcon />
        </div>
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.8em',
          alignItems: !props.sender ? 'flex-end' : 'flex-start',
          width: '100%',
        }}
      >
        {props.messages.map((m, i) => (
          <Message key={m.id} {...m} last={i === props.messages.length - 1} />
        ))}
      </div>
    </MessageGroupWrapper>
  );
};

const MessageGroupWrapper = styled.div`
  font-size: 14px;
  display: flex;
  align-items: flex-start;
`;

const Message = (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: props.last && !props.sender ? 'flex-end' : 'unset',
    }}
  >
    <div
      style={{
        maxWidth: '30rem',
        width: 'fit-content',
        border: '1px solid #EEEEEE',
        padding: '10px 16px',
        background: !props.sender ? '#d4eeda' : '#ffdada',
        borderRadius: '4px',
      }}
    >
      {props.content.type === 'text' ? <span>{props.content.body}</span> : null}
    </div>
    {props.last ? (
      <div
        style={{
          textAlign: !props.sender ? 'end' : 'start',
          marginTop: '4px',
          fontSize: '10px',
          color: '#929398',
        }}
      >
        {props.date}
      </div>
    ) : null}
  </div>
);
const StyledTab = styled(Tab)`
  min-width: 130px;
  font-family: Camphor;
  &.Mui-selected {
    background: #1a1a1a !important;
    border-radius: 4px 0px 0px 4px;
    border: 2px solid #1a1a1a;
    color: #ffffff !important;
    font-family: 'Camphor';
    font-weight: 600;
    user-select: none !important;
  }
  &:focus::after {
    background-color: #ffffff00 !important;
  }
`;

export default BookkeepingMaster;

const IntiateBookkeepingEntry = ({ companyId }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [processing, setProcessing] = React.useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onSubmit = (data) => {
    setProcessing(true);

    dataProvider
      .post(`company_bookkeeping_master/company/${companyId}/initiate-bookkeeping/${data.year}`, {})
      .then(({ data, error }) => {
        if (data?.key === 'status' && data?.value === 'ok') {
          notify('Bookkeeping initiated successfully', 'success');
          return;
        }
        if (error) {
          throw new Error(error);
        }
      })
      .catch((error) => {
        notify(error.message || error.error || error.code || error, 'error');
      })
      .finally(() => setProcessing(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid container className="styledGrid">
        <Grid item xs={6}>
          <CustomTextField
            control={control}
            label="Year"
            name="year"
            defaultValue={new Date().getFullYear() - 1}
          />
        </Grid>
        <Grid item xs={3}>
          <SubmitButton processing={processing} label="Initiate Bookkeeping" fullWidth />
        </Grid>
      </Grid>
    </form>
  );
};
