import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { format, isValid, parse } from 'date-fns'; // <-- New import
import React, { useMemo, useState } from 'react';
import { CSVMapping, Transaction } from './Transaction';

const clean = (str: string): string => str.replace(/\s+/g, ' ').trim();

const cleanAmount = (str: string) =>
  clean(str)
    .replace(/[$,]/g, '')
    .replace(/\u00A0/g, '')
    .replace(/—|\u2013|\u2012|\u2212/g, '-');

// New helper to parse and format dates
const parseAndFormatDate = (inputDate: string): string => {
  const possibleFormats = [
    'MM/dd/yyyy',
    'MM-dd-yyyy',
    'dd/MM/yyyy',
    'dd-MM-yyyy',
    'dd MMM, yyyy',
    'MMM dd, yyyy',
    'dd MMM', // without year, will use current year
    'MMM dd', // without year, will use current year
  ];
  let parsedDate;
  for (const fmt of possibleFormats) {
    parsedDate = parse(inputDate, fmt, new Date());
    if (isValid(parsedDate)) {
      return format(parsedDate, 'MMM dd, yyyy');
    }
  }
  throw new Error(`Unrecognized date format: ${inputDate}`);
};

interface CSVMappingModalProps {
  csvData: string;
  onMappingSubmit: (transactions: Transaction[]) => void;
  onClose: () => void;
}

const CSVMappingModal: React.FC<CSVMappingModalProps> = ({ csvData, onMappingSubmit, onClose }) => {
  // CONSTANT to control preview rows count
  const RECORDS_PREVIEW_COUNT = 3;

  // Split CSV into lines, filter empty lines
  const csvLines = useMemo(
    () => csvData.split('\n').filter((line: string) => line.trim() !== ''),
    [csvData]
  );
  const csvHeaders = useMemo(
    () => (csvLines.length > 0 ? csvLines[0].split(',').map((h: string) => h.trim()) : []),
    [csvLines]
  );
  const csvRecords = useMemo(
    () =>
      csvLines.length > 1
        ? csvLines
            .slice(1, 1 + RECORDS_PREVIEW_COUNT)
            .map((line: string) => line.split(',').map((v: string) => v.trim()))
        : [],
    [csvLines, RECORDS_PREVIEW_COUNT]
  );

  // Define required and optional fields for mapping
  const requiredFields: Array<{ key: keyof CSVMapping; label: string }> = [
    { key: 'amount', label: 'Amount' },
    { key: 'date', label: 'Date' },
    { key: 'description', label: 'Description' },
    { key: 'direction', label: 'Direction' },
  ];
  const optionalFields: Array<{ key: keyof CSVMapping; label: string }> = [
    { key: 'name', label: 'Name (optional)' },
  ];
  const allFields = [...requiredFields, ...optionalFields];

  // Default to "Incoming" (option 1)
  const [mapping, setMapping] = useState<CSVMapping>({
    amount: '',
    date: '',
    description: '',
    name: '',
    direction: 'Incoming',
  });

  const handleMappingChange = (field: keyof CSVMapping, value: string) => {
    setMapping({ ...mapping, [field]: value });
  };

  // For fields other than "direction", use CSV header selection preview.
  const getHeaderData = (header: string) => {
    const index = csvHeaders.indexOf(header);
    if (index !== -1 && csvRecords.length && csvRecords[0][index] !== undefined) {
      return `${header}: ${csvRecords[0][index]}`;
    }
    return '-';
  };

  // For direction, map the selected value to friendly text.
  const getDirectionData = (dir: string) => {
    if (dir === 'INCOMING_POSITIVE') return 'Incoming as Positive';
    if (dir === 'OUTGOING_POSITIVE') return 'Outgoing as Positive';
    return '-';
  };

  const getCreditDebitData = (dir: string, rawAmount: number) => {
    if (dir === 'INCOMING_POSITIVE') return rawAmount > 0 ? 'CREDIT' : 'DEBIT';
    if (dir === 'OUTGOING_POSITIVE') return rawAmount > 0 ? 'DEBIT' : 'CREDIT';
    throw new Error('Invalid direction mapping');
  };
  const handleSubmit = () => {
    // Parse all CSV records (excluding header)
    const lines = csvData.split('\n').filter((line: string) => line.trim() !== '');
    const headers = lines.length ? lines[0].split(',').map((h: string) => h.trim()) : [];
    // Get indexes for the required fields using mapping
    const idxAmount = headers.indexOf(mapping.amount);
    const idxDate = headers.indexOf(mapping.date);
    const idxDesc = headers.indexOf(mapping.description);
    const idxName = mapping.name ? headers.indexOf(mapping.name) : -1;

    const transactions: Transaction[] = [];
    lines.slice(1).forEach((line: string) => {
      const cols = line.split(',').map((cell: string) => cell.trim());
      // Only process if required column values exist
      if (idxAmount === -1 || idxDate === -1 || idxDesc === -1) return;
      let rawAmount = parseFloat(cleanAmount(cols[idxAmount]) || '0');
      const amount = Math.abs(rawAmount);
      const transaction: Transaction = {
        amount,
        date: parseAndFormatDate(cols[idxDate]), // <-- Use helper to format date
        description: clean(cols[idxDesc]),
        direction: getCreditDebitData(mapping.direction, rawAmount),
        name: mapping.name && cols[idxName] ? clean(cols[idxName]) : '',
        // ...other fields as needed...
      };
      if (idxName !== -1) {
        transaction.name = cols[idxName];
      }
      transactions.push(transaction);
    });
    onMappingSubmit(transactions);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Map CSV Columns</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight="bold">
              Field
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight="bold">
              {/* For "direction", we show fixed options */}
              {`CSV Column / ${'Direction'}`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" fontWeight="bold">
              First Record Mapping
            </Typography>
          </Grid>
          {allFields.map(({ key, label }) => (
            <React.Fragment key={key}>
              <Grid item xs={4}>
                <Typography>{label}</Typography>
              </Grid>
              <Grid item xs={4}>
                {key === 'direction' ? (
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="direction-select-label">Select Option</InputLabel>
                    <Select
                      labelId="direction-select-label"
                      value={mapping.direction || ''}
                      label="Select Option"
                      onChange={(e) => handleMappingChange('direction', e.target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="INCOMING_POSITIVE">Incoming as Positive</MenuItem>
                      <MenuItem value="OUTGOING_POSITIVE">Outgoing as Positive</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id={`${key}-select-label`}>Select Column</InputLabel>
                    <Select
                      labelId={`${key}-select-label`}
                      value={mapping[key] || ''}
                      label="Select Column"
                      onChange={(e) => handleMappingChange(key, e.target.value)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {csvHeaders.map(
                        (
                          header:
                            | boolean
                            | React.ReactChild
                            | React.ReactFragment
                            | null
                            | undefined,
                          idx: React.Key | null | undefined
                        ) => (
                          <MenuItem
                            key={idx}
                            value={header as string | number | readonly string[] | undefined}
                          >
                            {header}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body2">
                  {key === 'direction'
                    ? getDirectionData(mapping.direction)
                    : mapping[key]
                    ? getHeaderData(mapping[key] || '')
                    : '-'}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        {/* CSV Snippet Preview Table */}
        <Typography variant="h6" sx={{ mt: 3 }}>
          CSV Preview (First {RECORDS_PREVIEW_COUNT} Records)
        </Typography>
        <div style={{ overflowX: 'auto', width: '600px' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {csvHeaders.map(
                  (
                    header:
                      | boolean
                      | React.ReactChild
                      | React.ReactFragment
                      | React.ReactPortal
                      | null
                      | undefined,
                    idx: React.Key | null | undefined
                  ) => (
                    <TableCell key={idx}>{header}</TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {csvRecords.map((record: any[], recIndex: React.Key | null | undefined) => (
                <TableRow key={recIndex}>
                  {record.map(
                    (
                      cell:
                        | boolean
                        | React.ReactChild
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | undefined,
                      cellIdx: React.Key | null | undefined
                    ) => (
                      <TableCell key={cellIdx}>{cell}</TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit Mapping
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CSVMappingModal;
